/* Hide image on mobile screens */
strong {
  color:#2a632c;
}
.hide-on-mobile {
    display: block;
  }
  
  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }