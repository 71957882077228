.hero {
    background: url('https://images.alphacoders.com/135/thumb-1920-1351306.png') no-repeat center center;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  