/* News Card Styles */
.news-card {
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial shadow */
    transition: transform 0.3s ease;
    width: 400px; /* Adjusted width */
    height: 550px; /* Adjusted height */
    margin: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative; /* To control the glow from inside the card */
  }
  
  /* Hover effect for card (move up slightly and trigger glow animation) */
  .news-card:hover {
    transform: translateY(-10px); /* Slightly move the card up */
    animation: glow 1s ease-in-out infinite; /* Apply glow animation */
  }
  
  /* Glow Animation Keyframes */
  @keyframes glow {
    0% {
      box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); /* Initial small glow */
    }
    50% {
      box-shadow: 0 0 15px rgba(0, 255, 0, 1); /* Larger and brighter glow */
    }
    100% {
      box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); /* Return to initial glow */
    }
  }
  
  /* Image styling */
  .news-card-image {
    width: 100%;
    height: 300px; /* Adjusted height of the image */
    object-fit: cover;
    border-bottom: 2px solid #f0f0f0;
  }
  
  /* Content section (title, description, button) */
  .news-card-content {
    padding: 20px;
    flex-grow: 1;
  }
  
  /* Title styling */
  .news-card-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Description text styling */
  .news-card-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  /* Read more button */
  .news-card-readmore {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  /* Button hover effect */
  .news-card-readmore:hover {
    background-color: #0056b3;
  }
  