.news-articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 60px; /* Adjust depending on your header */
  }
  
  /* Mobile responsiveness: stack the cards one on top of the other */
  @media (max-width: 768px) {
    .news-articles {
      flex-direction: column;
      align-items: center;
    }
  
    .news-card {
      width: 90%; /* Make the cards smaller on mobile */
      margin: 10px 0; /* Add gap between cards */
    }
  }
  