/* General header styles */
header {
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  padding: 15px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays on top of content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

/* Logo styling */
.logo {
  color: white;
  font-size: 24px;
  flex-grow: 1; /* Allow the logo to take up space */
  text-align: center; /* Center the logo on desktop */
}

/* Navigation links container (desktop) */
nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Navigation links */
nav a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
}

/* On hover, links will glow brighter */
nav a:hover {
  color: #00ff00; /* Bright green glow effect */
}

/* Remove purple color after clicking on links (focus and active states) */
nav a:focus,
nav a:active {
  outline: none; /* Remove the default outline */
  color: white;  /* Keep color white when clicked */
}

/* Hamburger menu for mobile */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  order: -1; /* Move hamburger to the left */
}

/* Hamburger lines */
.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
}

/* Panel for mobile nav links (initially hidden) */
.nav-links {
  position: fixed;
  top: 45px;
  left: 0;
  right: 0;
  width: 25%;
  background-color: rgba(0, 0, 0, 0.9); /* Darker background for the panel */
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  transition: transform 0.3s ease;
  transform: translateY(-100%); /* Initially hidden above the screen */
}

/* When the panel is active (after clicking the hamburger menu) */
.nav-links.active {
  display: flex;
  transform: translateY(0); /* Slide down */
}

/* Mobile styles */
@media (max-width: 768px) {
  nav {
    display: none; /* Hide the regular nav links */
  }

  .hamburger {
    display: flex; /* Show the hamburger menu */
  }

  .logo {
    text-align: left; /* Align logo to the left for mobile */
  }
}
