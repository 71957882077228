.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
    font-size: 1rem;
  }
  
  .footer p {
    margin: 0;
  }
  
  .footer a {
    color: #ff7f00;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  